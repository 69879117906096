import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '24px',
    width: '100%',
    minHeight: '72px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 hsla(0,0%,87.5%,.5)',
    border: '1px solid rgba(0,0,0,.1)',
    backgroundColor: '#fff',
    marginBottom: '10px',
    padding: '8px 16px 8px 24px',
    color: 'black',
    cursor: 'pointer',
    userSelect: 'none',
    lineHeight: '1.15',
    display: 'flex',
    alignItems: 'center',
  },
  p: {
    marginLeft: '15px',
    margin: '0px',
    textTransform: 'capitalize',
  },
}));

export default useStyles;
