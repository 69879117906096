export default function data(
  state = {
    loading: true,
    company: {},
    categories: [],
    category: {},
    modalCategory: false,
    product: {},
    modalProduct: false,
  },
  action
) {
  switch (action.type) {
    case '@data/LOAD': {
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    }

    case '@data/LOADING': {
      return {
        ...state,
        loading: !state.loading,
      };
    }

    case '@data/CATEGORY': {
      return {
        ...state,
        ...action.data,
        modalCategory: true,
      };
    }

    case '@data/MODAL_CATEGORY_CLOSE': {
      return {
        ...state,
        modalCategory: false,
      };
    }

    case '@data/PRODUCT': {
      return {
        ...state,
        ...action.data,
        modalProduct: true,
      };
    }

    case '@data/MODAL_PRODUCT_CLOSE': {
      return {
        ...state,
        modalProduct: false,
      };
    }

    default:
      return state;
  }
}
