import React from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Header from './Header';
import Container from './Container';

import useStyles from './styles';

function ModalProduct() {
  const classes = useStyles();
  const { modalProduct, product } = useSelector(state => state.data);

  return (
    <Drawer anchor="bottom" open={modalProduct}>
      <div className={classes.root}>
        <Header />
        <Container data={product} />
      </div>
    </Drawer>
  );
}

export default ModalProduct;
