import { makeStyles } from '@material-ui/core';
import backgroundDefault from '../../../assets/logo.png';

const height = window.screen.height / 4;

const useStyles = makeStyles({
  root: {
    padding: '30px 15px 0px',
  },
  h5: {
    padding: '0px',
    margin: '0px',
    lineHeight: '1.3em',
    fontSize: '26px',
  },
  h1: {
    padding: '0px',
    margin: '0px',
    textTransform: 'capitalize',
    lineHeight: '1.3em',
    fontSize: '36px',
  },
  cover: {
    height: `${height}px`,
    backgroundImage: props =>
      props.background
        ? `url(${props.background})`
        : `url(${backgroundDefault})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '0px',
    border: '0px',
    borderRadius: '16px',
    margin: '15px 0px 0px',
  },
});

export default useStyles;
