/* eslint-disable react/prop-types */
import React from 'react';

import useStyles from './styles';

function Container({ data }) {
  const classes = useStyles({ background: data.url });

  return (
    <div className={classes.root}>
      <div className={classes.cover} />
      <h1 className={classes.h1}>{`${data.reference} - ${data.name}`}</h1>
      <p className={classes.p}>{data.description}</p>
      <div className={classes.price}>
        <span className={classes.span}>preço</span>
        <strong className={classes.strong}>
          {`R$${data.price.toFixed(2)}`}
        </strong>
      </div>
    </div>
  );
}

export default Container;
