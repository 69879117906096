import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  ul: {
    padding: '15px',
    margin: '0px',
  },
  li: {
    padding: '30px 15px',
    marginBottom: '12px',
    background: '#fff',
    display: 'flex',
    borderRadius: '2px',
    boxShadow:
      '0 2px 4px -1px rgba(0,0,0,.2), 0 1px 10px 0 rgba(0,0,0,.12), 0 4px 5px 0 rgba(0,0,0,.14)',
    justifyContent: 'space-between',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '75%',
  },
  h1: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.25px',
  },
  p: {
    fontSize: '16px',
    lineHeight: '1.75',
    letterSpacing: '.5px',
    color: '#575757',
    width: '100%',
    margin: '15px 0',
  },
  button: {
    height: '40px',
    maxWidth: '125px',
    borderRadius: '2px',
    boxShadow: '0 2px 6px 0 hsla(0,0%,69.8%,.5)',
    backgroundColor: '#ed1c24',
    color: '#fff',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '40px',
    letterSpacing: '1.25px',
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    flexGrow: '0',
    textTransform: 'uppercase',
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  img: {
    width: '80px',
    height: '80px',
    borderRadius: '8px',
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },
  span: {
    fontSize: '12px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '2px',
    textAlign: 'center',
    color: '#575757',
    flexGrow: '1',
    textTransform: 'uppercase',
  },
  strong: {
    fontSize: '16px',
    fontWeight: '700',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '.15px',
    textAlign: 'center',
    color: '#000',
  },
});

export default useStyles;
