import React from 'react';
import { useSelector } from 'react-redux';

import useStyles from './styles';

function Header() {
  const company = useSelector(state => state.data.company);
  const classes = useStyles({ background: company.background });

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <h5 className={classes.h5}>Olá, bem-vindo(a)</h5>
        <h1 className={classes.h1}>{company.name.toLowerCase()}</h1>
        <div className={classes.cover} />
      </section>
    </div>
  );
}

export default Header;
