/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import useStyles from './styles';

function Header({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: '@data/MODAL_CATEGORY_CLOSE',
    });
  };

  return (
    <div className={classes.root}>
      <KeyboardBackspaceIcon
        className={classes.icon}
        onClick={() => handleClose()}
      />
      <h1 className={classes.h1}>{data.name}</h1>
      <span />
    </div>
  );
}

export default Header;
