import React from 'react';
import { useSelector } from 'react-redux';
import Category from './Category';

import useStyles from './styles';

function Body() {
  const classes = useStyles();
  const categories = useSelector(state => state.data.categories);

  return (
    <div className={classes.root}>
      <ul className={classes.ul}>
        {categories.map(category => (
          <Category key={category.category_id} data={category} />
        ))}
      </ul>
    </div>
  );
}

export default Body;
