import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f8f8f8',
    padding: '30px 0 30px',
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '150px',
  },
}));

export default useStyles;
