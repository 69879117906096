import { makeStyles } from '@material-ui/core';

const height = window.screen.height / 2 - 60;

const useStyles = makeStyles({
  root: {},
  cover: {
    width: '100%',
    height: `${height}px`,
    backgroundImage: props => `url(${props.background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '0px',
    border: '0px',
    borderRadius: '16px',
    margin: '15px 0px 0px',
  },
  h1: {
    textTransform: 'uppercase',
    marginTop: '24px',
    marginBottom: '12px',
    width: '100%',
    fontSize: '26px',
    fontWeight: '700',
    textAlign: 'center',
    color: '#000',
    lineHeight: '1.1',
  },
  p: {
    fontSize: '16px',
    lineHeight: '1.75',
    letterSpacing: '.5px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#000',
    maxWidth: '1024px',
    marginBottom: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },
  span: {
    fontSize: '12px',
    fontWeight: '500 ',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '2px',
    textAlign: 'center',
    color: '#575757',
    flexGrow: '1',
  },
  strong: {
    fontSize: '16px',
    fontWeight: '700',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '.15px',
    textAlign: 'center',
    color: '#000',
  },
});

export default useStyles;
