import React from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Header from './Header';
import Container from './Container';

import useStyles from './styles';

function ModalCategory() {
  const classes = useStyles();
  const { modalCategory, category } = useSelector(state => state.data);

  return (
    <Drawer className={classes.root} anchor="right" open={modalCategory}>
      <Header data={category} />
      <Container data={category} />
    </Drawer>
  );
}

export default ModalCategory;
