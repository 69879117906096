import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Fake from '../../assets/splash.png';
import api from '../../services/api';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import ModalCategory from '../../components/ModalCategory';
import ModalProduct from '../../components/ModalProduct';

import useStyles from './styles';

function Store({ match }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { params } = match;
  const service = api(params.token || '22');
  const dispatch = useDispatch();
  const company = useSelector(state => state.data.company);

  useEffect(() => {
    async function getData() {
      try {
        const data = await service.index();
        if (data) {
          const { company: com, categories } = data;

          dispatch({
            type: '@data/LOAD',
            data: {
              company: com,
              categories,
            },
          });
        }
      } catch (error) {
        dispatch({ type: '@data/LOADING' });

        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    }

    getData();
  }, [params, dispatch]);

  function beforeLloading() {
    return (
      <div className={classes.root}>
        <Avatar className={classes.avatar} src={Fake} alt="Gestor Food" />
      </div>
    );
  }

  function afterLoading() {
    return (
      <>
        <Header />
        <Body />
        <Footer />
        <ModalCategory />
        <ModalProduct />
      </>
    );
  }

  return company.name ? afterLoading() : beforeLloading();
}

export default Store;
