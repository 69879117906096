/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useStyles from './styles';

function Container({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  function formatDescription(text) {
    if (text && text.length >= 201) {
      return `${text.substring(0, 200)}...`;
    }

    return text;
  }

  useEffect(() => {
    setProducts([
      ...data.products.map(product => {
        return {
          ...product,
          formattedDescription: formatDescription(product.description),
        };
      }),
    ]);
  }, [data]);

  const handleProduct = product => {
    dispatch({
      type: '@data/PRODUCT',
      data: {
        product,
      },
    });
  };

  return (
    <div className={classes.root}>
      <ul className={classes.ul}>
        {products.map(product => (
          <li key={product.product_id} className={classes.li}>
            <div className={classes.text}>
              <h1 className={classes.h1}>{product.name}</h1>
              <p className={classes.p}>{product.formattedDescription}</p>
              <button
                type="button"
                className={classes.button}
                onClick={() => handleProduct(product)}
              >
                ver mais
              </button>
            </div>
            <div className={classes.image}>
              <img
                className={classes.img}
                src={product.url}
                alt={product.name}
              />
              <div className={classes.price}>
                <span className={classes.span}>preço</span>
                <strong className={classes.strong}>
                  {`R$${product.price.toFixed(2)}`}
                </strong>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Container;
