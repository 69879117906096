/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from './styles';

function Category({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCategory = category => {
    dispatch({
      type: '@data/CATEGORY',
      data: {
        category,
      },
    });
  };

  return (
    <li onClick={() => handleCategory(data)} className={classes.root}>
      <SubjectIcon style={{ color: '#c4c4c4' }} />
      <p className={classes.p}>{data.name.toLowerCase()}</p>
    </li>
  );
}

export default Category;
