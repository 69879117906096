/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: '@data/MODAL_PRODUCT_CLOSE',
    });
  };

  return (
    <div className={classes.root}>
      <CloseIcon onClick={() => handleClose()} />
    </div>
  );
}

export default Header;
