import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#c4c4c4',
  },
  h1: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '2.44',
    letterSpacing: '.13px',
    color: '#ed1c24',
    textTransform: 'uppercase',
  },
});

export default useStyles;
