import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    padding: '0px',
  },
  avatar: {
    width: '100vw',
    height: '100vh',
    borderRadius: 0,
  },
}));

export default useStyles;
