const api = token => {
  const baseURL = 'https://app.gestorfood.com/menu';

  const index = async function index() {
    const url = `${baseURL}/?t=${token}`;

    const res = await fetch(url, { method: 'GET' });

    const data = await res.json();

    return data;
  };

  return {
    index,
  };
};

export default api;
