import React from 'react';

import logo from '../../../assets/logo.png';
import useStyles from './styles';

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <a href="https://gestorfood.com" target="_blank">
        <img className={classes.img} src={logo} alt="Gestor Food" />
      </a>
    </div>
  );
}

export default Footer;
