import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    top: 0,
    left: 0,
    position: 'sticky',
    width: '100vw',
    minHeight: '80px',
    padding: '5px 15px',
    background: '#fff',
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    boxShadow:
      '0 1px 5px 0 rgba(0,0,0,.2), 0 3px 1px -2px rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.14)',
  },
  icon: {
    color: '#c4c4c4',
  },
  h1: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '2.44',
    letterSpacing: '.13px',
    color: '#ed1c24',
    textTransform: 'uppercase',
  },
});

export default useStyles;
